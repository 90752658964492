













































































































































































































































































































































































import {FactService, SHIFT_UPDATED, SHIFT_APPROVED, SHIFT_REJECTED} from '@/services/FactService';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {State, Getter, Action} from 'vuex-class';
import utils from './Utils';
import _ from 'lodash';
import FactSelection from '@/components/common/form/fact/FactSelection.vue';
import FactPhoneNumber from '@/components/common/form/fact/FactPhoneNumber.vue';
import FactAgree from '@/components/common/form/fact/FactAgree.vue';
import FactText from '@/components/common/form/fact/FactText.vue';
import {CMSContentService} from '@/services/CMSService';
import PolicyService from '@/services/PolicyService';
import Benefits from '@/components/products/Benefits.vue';
import Breakdown from '@/components/products/Breakdown.vue';
import Util from '@/utils/Util';
import ProductEngineUtils, { BUSINESS_SECTION_NAME, USE_POLICY } from '@/utils/ProductEngineUtils';
import FactTooltip from '@/components/common/form/fact/FactTooltip.vue';
import EventBus from '@/common/EventBus';
import {IApp, IAuth, IFactUpdatePayload, IProposal, IQuotation, IQuotationProduct, IShiftResult} from '@/interfaces';
import Illustration from '@/components/common/Illustration.vue';
import GAUtil from '@/utils/GAUtil';
import WorkflowService from '@/services/WorkflowService';

const DECLARATION_REGEX: RegExp = /declaration/;
const SUBMISSION_TIMEOUT = 30 * 1000;

@Component({
  name: 'Summary',
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    FactTooltip,
    FactSelection,
    Benefits,
    FactText,
    FactPhoneNumber,
    FactAgree,
    Breakdown,
    Illustration
  }
})
export default class Summary extends Vue {

  public hasPayments: any = [];

  @State private app!: IApp;
  @State private cms: any;
  @State private auth!: IAuth;
  @State private products: any;
  @State private proposal!: IProposal;
  @State private quotation!: IQuotation;
  @Prop() private componentIndex!: number;
  @Prop() private next: any;
  @Prop() private img: any;
  @Prop() private afterUW!: boolean;

  @Getter('cms/getMessageByCode') private getMessageByCode: any;
  @Getter('cms/getProductContentByProduct') private getProductContentByProduct: any;
  @Getter('proposal/getSelectedProducts') private getSelectedProducts: any;
  @Getter('proposal/getInvoicesPriceToPay') private getInvoicesPriceToPay: any;
  @Getter('proposal/getInvoicesTotalTax') private getInvoicesTotalTax: any;
  @Getter('quotation/getAllCustomProductTrees') private getAllCustomProductTrees!: () => any;
  @Getter('quotation/getAllProductLevelFacts') private getAllProductLevelFacts!: () => any;
  @Getter('quotation/getQuotedPrices') private getQuotedPrices!: () => any;
  @Getter('quotation/isPriceValidByProductId') private isPriceValidByProductId!: (productID: string, isContinuation: boolean) => boolean;
  @Getter('quotation/getFactByFactID') private getFactByFactID!: (factID: string) => any;
  @Getter('quotation/getFactValueByFactID') private getFactValueByFactID!: (factID: string) => string;
  @Getter('quotation/getFactCMSFormatted') private getFactCMSFormatted!: (factID: string) => string;
  @Getter('quotation/getConfigFactTypeByFactType') private getConfigFactTypeByFactType!: (factID: string) => string;
  @Getter('quotation/getTotalQuotedPriceToPay') private getTotalQuotedPriceToPay!: (isContinuation: boolean) => number;
  @Getter('quotation/getTotalQuotedTax') private getTotalQuotedTax!: (isContinuation: boolean) => number;
  @Getter('quotation/getProductPriceWithType') private getProductPriceWithType!: (productID: string, priceType: string, isAfterUW: boolean) => number;
  @Getter('quotation/getPriceBucket') private getPriceBucket!: (productID: string, isAfterUW: boolean) => any[];
  @Getter('quotation/getCoverageDetailFacts') private getCoverageDetailFacts!: () => any;
  @Getter('quotation/getTotalTaxPercentage') private getTotalTaxPercentage!: () => number;
  @Getter('quotation/isAllQuoteValid') private isAllQuoteValid!: () => boolean;
  @Action('quotation/setQuotation') private setQuotation: any;
  @Action('quotation/updateFactUpload') private updateFactUpload!: () => void;
  @Action('app/setLoadingText') private setLoadingText: any;
  @Action('proposal/setPaymentSkipped') private setPaymentSkipped: any;
  @Action('proposal/setNoPayment') private setNoPayment: any;
  @Action('proposal/submitQuotes') private submitQuotes: any;
  @Action('proposal/reset') private proposalReset: any;
  @Action('proposal/setProductPolicyNumber') private setProductPolicyNumber: any;
  @Action('proposal/setProductPolicyId') private setProductPolicyId: any;
  @Action('proposal/setProposalInvoiceId') private setProposalInvoiceId: any;
  @Action('proposal/setInvoices') private setInvoices: any;
  @Action('proposal/setRefund') private setRefund: any;
  @Action('proposal/setIsEndorsment') private setIsEndorsment: any;
  @Action('proposal/resetSaveProposalAttempts') private resetSaveProposalAttempts: any;
  @Action('proposal/setProposalNumber') private setProposalProposalId: any;
  @Action('proposal/setReviewed') private setReviewed: any;
  @Action('proposal/setConverted') private setConverted: any;
  @Action('proposal/setQuoted') private setQuoted: any;
  @Action('proposal/setChangeAfterReview') private setChangeAfterReview: any;
  @Action('app/setPardotAvailability') private setPardotAvailability: any;
  @Action('app/resetRenewalResponse') private resetRenewalResponse: any;
  @Action('app/setProposalNumber') private setAppProposalNumber: any;
  @Getter('quotation/getProducts') private getProducts!: () => any;
  @Action('quotation/updateFact') private updateFact!: (payload: IFactUpdatePayload) => void;
  @Action('quotation/setShiftResult') private setShiftResult!: (shiftResults: IShiftResult[]) => void;
  @Action('quotation/updateRenewalQuote') private updateRenewalQuote: any;
  @Action('app/getQuotes') private getQuotes: any;
  @Action('app/setBundleId') private setBundleId: any;
  @Action('app/setPolicyIds') private setPolicyIds: any;

  get productTrees() {
      return this.getQuotedPrices();
  }

  get uniqueFacts() {
    return this.getCoverageDetailFacts();
  }

  get isValidToSubmit() {
    if (this.afterUW) {
      return true;
    } else {
      const isValid = this.isAllQuoteValid();

      // pop up technical error for invalid
      if (!isValid) {
        Util.gtmLogCustomEvent(this, 'click', 'tracked_error', {
          event_params : {
            error_type: 'technical-issue',
            error_message:  'Invalid Product config'
          }
        });
        this.$dialog.open({ type: 'technical-issue', info: 'Invalid product config' });
      }

      return isValid;
    }
  }

  get isAllowExternalPricing() {
    const code = this.proposal
      && this.proposal.products
      && this.proposal.products.length > 0
      && this.proposal.products[0].code;

    const product = _.find(this.products, (productEl) => productEl.code === code);
    return product?.allowExternalPricing;
  }

  private declarationMissing = false;
  private shiftMessage = '';
  private shiftWarningProducts: any[] = [];
  private deadline = 0;
  private pendingPolicies = {};
  private refunds = 0;
  private refundsTax = 0;
  private loadingStageMessageCode = {
    SUBMITTING: {
      code: 'SUBMITTING',
      message: 'product.summary.loading.submitting'
    },
    CONVERTING: {
      code: 'CONVERTING',
      message: 'product.summary.loading.converting'
    },
    TIMEOUT: {
      code: 'TIMEOUT',
      message: 'product.summary.loading.timeout'
    },
    PRODUCT: {
      code: 'PRODUCT',
      message: 'product.summary.loading.product'
    }
  };
  private submissionTimeout = 1000;
  private cmsLoadTimeout = 3000;
  private declarationFacts: any[] = [];
  private processDeclarationFacts: any = null;
  private pardotSent: boolean = false;

  private shiftWarnings: any = [];

  public validate(this: any) {
    if (this.quotation.shiftResults.length > 0 && this.hasPayments.length > 0) {
      return this.next();
    }

    this.$resetErrors();
    this.declarationMissing = false;
    this.$validator.validate().then((result: any) => {
      if (result && this.isValidToSubmit) {
        this.submit();
      } else {
        this.declarationMissing = true;
        Util.gtmLogCustomEvent(this, 'click', 'tracked_error', {
              event_params : {
                error_type: 'form-error',
                error_message:  'Please provide valid input'
              }
        });
        this.$emit('error', this.xxErrors);
      }
    });
  }

  public getQuote(this: any) {
    this.$resetErrors();
    this.declarationMissing = false;
    this.$validator.validate().then((result: any) => {
      if (result && this.isValidToSubmit) {
        const productId = this.proposal.products[0].id;
        const quote = this.quotation.products[productId];
        const config = quote.storedSession;
        WorkflowService.runWorkflowSync(
          'dom-get-quote',
          { productId, productConfig: config },
          ['underwriting', 'pricing']
        ).then((response) => {
          const underwriting = response.data.data.underwriting;
          const pricing = response.data.data.pricing;
          this.setQuoted(true);
          this.setQuotation({underwriting, pricing, allowExternalPricing: this.isAllowExternalPricing});
          this.$dialog.open({type: 'price-change'});
        });
      } else {
        this.declarationMissing = true;
        this.$emit('error', this.xxErrors);
      }
    });
  }

  private submit() {
    this.sendToCRM();
    this.updateLoadingStage(this.loadingStageMessageCode.SUBMITTING.code);
    this.getQuotesAndPolicies();
  }

  private created() {
    this.processDeclarationFacts = _.debounce(this.processDeclarationFactsFunc, this.cmsLoadTimeout, {
      leading: true,
      trailing: false
    });
    EventBus.$on('TreeUpdated', _.debounce((id) => {
      EventBus.$emit('TreeUpdated-Summary', id);
    }, Util.RE_RENDER_DEBOUNCE, Util.RE_RENDER_DEBOUNCE_OPTIONS));
    this.initStep();
    this.resetSaveProposalAttempts();
  }

  private mounted() {
    this.submit = _.debounce(this.submit, this.submissionTimeout, {
      leading: true,
      trailing: false
    });
    if (this.app.isContinuation) {
        GAUtil.ODLogContinuation();

    }
    const isReviewRequired = Object.values(this.quotation.products)
      .some((product) => product.isReviewRequired);
    if (!isReviewRequired) {
      Util.gtmLogEcommerceEvent(
        this,
        'view_cart',
        JSON.parse(this.productTrees[0]?.productDetail?.definition)?.name?.en,
        this.app.targetSegment || 'DEMO',
        {
          items: this.productTrees.map((productTree) => ({
            item_name: JSON.parse(productTree?.productDetail?.definition)?.name?.en,
            item_id: productTree.productDetail?.id,
            item_category: this.app.targetSegment,
            item_code: productTree.productDetail?.code,
            quantity: 1,
            price: productTree.totalPrice
          }))
        }
      );
    }
  }

  private updated() {
    if (!this.pardotSent && this.contactEmail && this.contactPhone && this.app.isContinuation) {
      if (this.app.originUrl) {
        const pardotObj = {
          email: this.contactEmail,
          mobile: this.contactPhone,
          ContinuationLink: encodeURIComponent(this.app.originUrl),
          ApplicationStatus: 'CONTINUATION_LINK_ACCESSED'
        };
        this.reportContinuationUsed(pardotObj);
        this.pardotSent = true;
        GAUtil.ODLogContinuation();
      }
    }
  }

  private async reportContinuationUsed(pardotObj: any) {
    _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_CONTINUATION_ACCESSED, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)(pardotObj);
  }

  private activated() {
    this.initStep();
    this.$global.enterAnimation();
    Util.gaLogPageView(this, `/summary+${sessionStorage.subSegment ? sessionStorage.subSegment : sessionStorage.targetSegment}`);
    this.$nextTick(() => {
      EventBus.$emit('stepper-idle');
    });
    // EventBus.$on('TreeUpdated-Summary', _.debounce((id) => {
    // }));
    GAUtil.ODLogSummary();
  }

  private deactivated() {
    EventBus.$emit('stepper-busy');
    EventBus.$off('TreeUpdated-Summary');
  }

  private initStep() {
    if (this.afterUW) {
      this.checkRefunds();
    }
    this.processDeclarationFacts();
  }

  private async processDeclarationFactsFunc() {
    const facts: any[] = [];
    for (const id of Util.getDeclarationFacts()) {
      if (id) {
        const fact = await this.getFactCMSFormatted(id);
        if (fact) {
          facts.push(fact);
        }
      }
    }
    this.declarationFacts = facts;
  }

  private dynamicFlex(fact: any, flexSize: string) {
    return (
      (fact.id.match(DECLARATION_REGEX) && flexSize === 'xs12') ||
      (!fact.id.match(DECLARATION_REGEX) && flexSize === 'xs6')
    );
  }

  private isReadOnly(facts: any[]) {
      const readonlySections = this.app.config.shiftMappings.COVERAGE_DETAILS_READONLY_SECTIONS || [];
      return !facts || facts.every((fact) => readonlySections.find((sectionPrefix) => fact.id.startsWith(sectionPrefix)) || (this.auth.guestParty && fact.id.startsWith('applicant')));
  }

  get contactInfoFacts() {
    return this.contactMobileFact && this.contactEmailFact ?
       [this.contactEmailFact, this.contactMobileFact] : [];
  }

  get contactMobileFact() {
    const factId = Object.keys(this.app.contactInfoFacts).filter((key) => key.endsWith('phone'))[0];
    return this.getFactByFactID(factId);
  }

  get contactEmailFact() {
    const factId = Object.keys(this.app.contactInfoFacts).filter((key) => key.endsWith('email'))[0];
    return this.getFactByFactID(factId);
  }

  get contactPhone() {
    return this.proposal.contactInfo.phone;
  }

  get contactEmail() {
    return this.proposal.contactInfo.email;
  }

  // this method need to be move to a shared place.
  get getAfterReviewInterpolationObject() {
    const proposalFacts: any = {};
    proposalFacts.applicantFullName = `${this.proposal.contactInfo.givenName} ${this.proposal.contactInfo.familyName}`;

    try {
      // only gets facts from the first product

      const productTree = this.getAllCustomProductTrees();
      if (productTree && productTree.length > 0 && productTree[0].allFacts) {
        const firstProduct = productTree[0];
        firstProduct.allFacts.forEach((fact) => {
            proposalFacts[fact.id.replaceAll('.', '_').replaceAll(':', '_')] = fact.currentValue;
        });
        if (firstProduct.components) {
          firstProduct.components.forEach((component) => {
            if (component.allFacts) {
              component.allFacts.forEach((fact) => {
                proposalFacts[fact.id.replaceAll('.', '_').replaceAll(':', '_')] = fact.currentValue;
              });
            }
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
    return proposalFacts;
  }

  get tax() {
    return utils.getTax(this.proposal);
  }

  get warnings() {
    const fact = this.getFactByFactID(Util.WARNINGS_FACT_ID);
    return fact &&  fact.type === 'INFO' ? fact : null;
  }

  get declarations() {
    return this.declarationFacts;
  }

  get coinsurance() {
    const fact = this.getFactByFactID(Util.COINSURANCE_FACT_ID);
    return fact && fact.readonly;
  }

  private refundWOTax() {
    return this.refunds - this.refundsTax;
  }

  get getErrorMessage() {
    return this.shiftMessage;
  }

  get getWarningMessage() {
    const message = this.shiftMessage;
    const products = this.shiftWarningProducts;
    let title: string = '';
    _.forEach(products, (product: any, index: number) => {
      title += this.getProductTitle(product) + (index < products.length - 2 ? ', ' :  index < products.length - 1 ? ' and ' : '');
    });
    return message.replace('{0}', `<b>${title}</b>`);
  }

  get getContinueAsMainButton() {
    const continueAsMainButton = {
      type: 'main',
      text: this.$t('button.continue'),
      onClick: () => {
        EventBus.$emit('close-loading-dialog');
        this.getQuotesAndPolicies();
      }
    };
    return continueAsMainButton;
  }

  get getProceedCatalogueButton() {
    const proceedCatalogueButton = {
      text: this.$t('button.proceedCatalogue'),
      onClick: this.acknowledgeShiftError
    };
    return proceedCatalogueButton;
  }

  get getCntOrProCatButtons() {
    if (this.isExternalPricing()) {
      return [this.getContinueAsMainButton];
    } else {
      return [this.getContinueAsMainButton, this.getProceedCatalogueButton];
    }
  }

  get continueOrSavedProposal() {
    return this.app.isContinuation || this.app.isSavedProposal;
  }

  private priceToPay() {
    return this.app.isContinuation ? this.getInvoicesPriceToPay(this.afterUW) : this.getTotalQuotedPriceToPay(this.continueOrSavedProposal);
  }

  private totalTax() {
    return this.app.isContinuation ? this.getInvoicesTotalTax(this.afterUW) : this.getTotalQuotedTax(this.continueOrSavedProposal);
  }

  private getBuckets(id: any)  {
    return this.getPriceBucket(id, this.afterUW);
  }

  private getProratedPrice(id: any) {
    return this.getProductPriceWithType(id, 'totalPrice', this.afterUW);
  }

  private getProratedDiscount(id: any) {
    return this.getProductPriceWithType(id, 'prorationDiscount', this.afterUW);
  }

  private getProductTitle(product: IQuotationProduct) {
    const pp = _.find(this.getSelectedProducts(), (fp: any) => fp.code === product.code);
    return pp && pp.productTitle;
  }

  private getBenefitValue(benefit) {
    return _.get(benefit, 'value', benefit.defaultValue);
  }

  private async checkRefunds() {
    const response = await PolicyService.getBundleStatus(this.app.bundleId).catch((e: any) => {
      if (e.code !== 404) {
        console.error(e.message || e);
      }
    });
    const invoiceId = _.get(response, 'data.data.invoiceId');
    if (!invoiceId) {
      return;
    }
    const type = _.get(response, 'data.data.type');
    this.setProposalInvoiceId(invoiceId);
    const invoiceStatus = await PolicyService.getInvoiceStatus(invoiceId).catch((e: any) => {
      console.error(e.message || e);
    });
    this.setInvoices(_.get(invoiceStatus, 'data.data', []).filter((i) => i.entryType === 'DEBIT'));

    const credits = _.get(invoiceStatus, 'data.data', []).filter((i) => i.entryType === 'CREDIT');
    let refunds = 0;
    let refundsTax = 0;
    credits.forEach((i) => {
      refunds += i.amount;
      refundsTax += i.taxAmount;
    });
    this.refunds = -refunds || 0;
    this.refundsTax = -refundsTax || 0;
    this.setRefund({
      refund: this.refunds,
      refundTax: this.refundsTax
    });

    if (type === 'ENDORSMENT') {
      this.setIsEndorsment(true);
    }
  }

  private getTaxPercentage() {
    return this.getTotalTaxPercentage();
  }

  private async refreshRenewal() {
    this.resetRenewalResponse();
    await this.getQuotes(this.app.proposalId).catch((e: any) => console.error(e));
  }

  private async getQuotesAndPolicies() {
    if (this.proposal.reviewed) {
      this.setReviewed(false);
      // if there is changes after review, create a new proposal.
      if (this.proposal.changeAfterReview) {
        this.setAppProposalNumber('');
        this.setProposalProposalId('');
        this.setConverted(false);
      } else {
        this.finish();
      }
    }

    if (this.afterUW) {
      for (const policyId of this.app.policyIds) {
        const statusResponse = await PolicyService.getPolicyStatus(policyId).catch((e: any) => {
          if (e.code !== 401) {
            this.shiftUnavailable();
          }
        });
        if (statusResponse === '401') {
          setTimeout(this.getQuotesAndPolicies, 1000);
          return;
        }
        if (statusResponse && statusResponse.status === 200) {
          const p = _.get(statusResponse, 'data.data', {});
          this.pendingPolicies[p.id] = {
            policyNumber: p.policyNumber,
            productId: p.productId,
            status: p.status,
            config: p.config
          };
          this.setProductPolicyId({productId: p.productId, policyId: p.id});
        }
      }
      return this.checkResults();
    }

    if (this.app.isRenewal && this.app.proposalId) {
      await this.getQuotes(this.app.proposalId);
      await this.updateRenewalQuote();
    }

    // if proposal is not converted and not created, create a proposal.
    if (!this.proposal.converted && (!this.proposal.proposalId || this.proposal.saveProposalAttempts > 0)) {
      const response = await this.submitQuotes()
        .catch((e: any) => {
          if (e.code !== 401 && e.code !== 412) {
            this.shiftUnavailable();
          }
      });
      if (response === '401') {
        setTimeout(this.getQuotesAndPolicies, 1000);
        return;
      }
      if (response === '412') {
        if (this.app.isRenewal) {
          await this.refreshRenewal();
        }
        setTimeout(this.getQuotesAndPolicies, 1000);
        return;
      }
    }

    if (this.app.quoteOnly && !this.proposal.proposalId) {
      const products = this.getProducts();
      const quotation = {};
      for (const key in products) {
        if (Object.prototype.hasOwnProperty.call(products, key)) {
          const product = products[key];
          const prices = {};
          for (const bucket of product.price.buckets) {
            prices[bucket.id] = bucket.totalPrice - bucket.refund;
          }
          quotation[product.code] = prices;
        }
      }

      WorkflowService.runWorkflowSync('create-quote-review-task', {
        quotation,
        proposalId: this.proposal.proposalId,
      } , []);

      const query: any = Object.assign(
      this.$route.query,
      {
        language: this.app.language,
        type: '',
        completed: false
      });
      this.$router.push({ name: 'confirmation', query }, undefined, (error) => {
        this.resetLoadingStage();
      });
      return;
    }

    // if created but not converted, convert the proposal.
    if (this.proposal.proposalId && !this.proposal.converted) {
      const quotes: string[] = [];
      const selectedParties: any = {};
      _.each(this.proposal.createdQuotes, (createdQuote: any) => {
        if (createdQuote.id) {
          quotes.push(createdQuote.id);
        }
      });
      let convertResponse;
      if (this.app.savedProposal && this.app.savedProposal.policyPartyType && this.app.savedProposal.policyPartyId) {
        selectedParties[this.app.savedProposal.policyPartyType] = this.app.savedProposal.policyPartyId;
      }
      convertResponse = await PolicyService.convertPolicy({quotes, selectedParties})
      .catch((e: any) => {
        if (e.code !== 401 && e.code !== 412) {
          this.shiftUnavailable();
        }
      });
      if (convertResponse === '401') {
        setTimeout(this.getQuotesAndPolicies, 1000);
        return;
      }
      if (convertResponse === '412') {
        if (this.app.isRenewal) {
          await this.refreshRenewal();
        }
        setTimeout(this.getQuotesAndPolicies, 1000);
        return;
      }
      if (convertResponse && convertResponse.status === 200) {
        this.setConverted(true);
        this.pendingPolicies = [];
        const policies = _.get(convertResponse, 'data.data.policies', []);
        for (const p of policies) {
          this.pendingPolicies[p.id] = {policyNumber: p.policyNumber, productId: p.productId, status: p.status, config: p.config};
          this.setProductPolicyId({productId: p.productId, policyId: p.id});
        }
        this.setBundleId(_.get(convertResponse, 'data.data.bundle.id'));
        this.updateFactUpload();
      }
    }
    if (this.proposal.converted) {
      const bundleResponse = await PolicyService.getBundleStatus(this.app.bundleId).catch((e: any) => {
        if (e.code !== 401) {
          this.shiftUnavailable();
        }
      });
      if (bundleResponse === '401') {
        setTimeout(this.getQuotesAndPolicies, 1000);
        return;
      }
      if (bundleResponse && bundleResponse.status === 200) {
        // reset deadline if it exists
        this.deadline = 0;
        this.checkBundleStatus(bundleResponse);
      }
    }
  }

  private async checkBundleStatus(response: any) {
    let timeIsUp = false;
    if (this.deadline) {
      const now = +new Date();
      if (now > this.deadline) {
        timeIsUp = true;
      } else {
        const timeout = +new Date() + (10 * 1000);
        if (timeout > this.deadline) {
          this.updateLoadingStage(this.loadingStageMessageCode.TIMEOUT.code);
        } else {
          this.updateLoadingStage(this.loadingStageMessageCode.CONVERTING.code);
        }
      }
    } else {
      this.deadline = +new Date() + +_.get(this.app, 'config.timeouts.submission', SUBMISSION_TIMEOUT);
    }
    const status = _.get(response, 'data.data.status');
    if (status === 'CREATED' || status === 'FINISHING') {
      if (!timeIsUp) {
        const newResponse = await PolicyService.getBundleStatus(_.get(response, 'data.data.id'))
          .catch((e: any) => {
            console.error(e.message || e);
          });
        if (newResponse && newResponse.status === 200) {
          setTimeout(this.checkBundleStatus.bind(this, newResponse), 1000);
        } else {
          // in case of error on interface response is not present, we need to simulate to continue with flow
          const dummyResponse = {
            data:
              { data:
                  {
                    status: 'CREATED',
                    id: _.get(response, 'data.data.id')
                  }
              }
          };
          setTimeout(this.checkBundleStatus.bind(this, dummyResponse), 1000);
        }
      } else {
        this.timeIsUpHandler();
      }
    } else if (status === 'COMPLETE') {
      const invoiceId = _.get(response, 'data.data.invoiceId');
      this.setProposalInvoiceId(invoiceId);
      // add invoices
      const invoiceStatus = await PolicyService.getInvoiceStatus(invoiceId).catch((e: any) => {
       console.error(e.message || e);
      });
      this.setInvoices(_.get(invoiceStatus, 'data.data', []).filter((i) => i.entryType === 'DEBIT'));

      this.checkResults(_.get(response, 'data.data.id'));
    } else {
      this.shiftUnavailable();
    }
  }

  private updateLoadingStage(stage: string, productName?: any) {
    this.setLoadingText({message: this.$t(this.loadingStageMessageCode[stage].message), productName});
  }

  private resetLoadingStage() {
    this.setLoadingText(null);
  }

  private async checkResults(bundleId?: string) {
    this.hasPayments = [];
    this.shiftWarnings = [];
    const warnings = [];
    for (const id in this.pendingPolicies)  {
      if (!this.pendingPolicies.hasOwnProperty(id)) {
        continue;
      }
      const pId = this.pendingPolicies[id].productId;
      const quotedProduct = this.quotation.products[pId];
      this.updateLoadingStage(this.loadingStageMessageCode.PRODUCT.code, quotedProduct.product.name);

      const newStatus = await PolicyService.getPolicyStatus(id).catch((e: any) => {
        console.error(e.message || e);
      });
      if (!newStatus || newStatus.status !== 200) {
        return this.shiftUnavailable();
      }

      const p = _.get(newStatus, 'data.data', {});
      const originalPrice = this.getProratedPrice(p.productId);
      if (p.status === 'PENDING_PAYMENT' || p.status === 'PENDING_PAYMENT_REVIEWED' || p.status === 'PENDING_PAYMENT_UNLAPSED' || p.status === 'LAPSING' ) {
        const newPrice = _.get(quotedProduct.customDescription, 'calculation.price.totalPrice');
        this.hasPayments.push(quotedProduct);
        this.setProductPolicyNumber({productId: p.productId, policyNumber: p.policyNumber});
        // ignore price comparison for product with external pricing.
        const quotedProductDetails = _.find(this.products, (productEl) => productEl.code === quotedProduct.code);
        if (originalPrice !== newPrice && quotedProductDetails.isAllowExternalPricing) {
          this.shiftWarnings.push({product: quotedProduct, message: originalPrice ? SHIFT_UPDATED : SHIFT_APPROVED});
          this.setReviewed(true);
          this.setChangeAfterReview(false);
        }
      } else if ((p.status === 'MANUAL_PRICING' || p.status === 'MANUAL_UNDERWRITING') && _.isFinite(originalPrice)) {
        this.shiftWarnings.push({product: quotedProduct, message: SHIFT_REJECTED});
      } else {
        this.setProductPolicyNumber({productId: p.productId, policyNumber: p.policyNumber});
      }
    }
    this.resetLoadingStage();

    this.setShiftResult(_.cloneDeep(this.shiftWarnings));

    if (this.proposal.reviewed) {
      this.setPolicyIds(_.keys(this.pendingPolicies));
      setTimeout(() => {
        EventBus.$emit('policy:reload');
      }, 500);
    }

    this.acknowledgeShiftWarning();
  }

  private finish() {
    this.resetLoadingStage();
    this.resetRenewalResponse();
    this.setAppProposalNumber('');
    this.setProposalProposalId('');
    if (this.hasPayments.length > 0) {
      this.next();
    } else {
      this.setPaymentSkipped(true);
      this.setNoPayment(true);
      Util.gaLogPaymentEvent(this, 'payment', Util.GA_PAYMENT_STATUSES_TYPE.SUCCESS, Util.GA_PAYMENT_STATUSES_RESULT.NOTHING_TO_PAY);
      const query: any = Object.assign(
        this.$route.query,
        {language: this.app.language}
      );
      this.$router.push({name: 'confirmation', query}, undefined, (error) => {
        this.resetLoadingStage();
        this.$dialog.open({ type: 'redirection-issue-summary', info: 'Summary: redirection to confirmation failed' });
      });
    }
  }

  private shiftWarning(products: any[], code: string, end: boolean = false) {
    const self = this;
    CMSContentService.getStaticText(code, this.app.language).then((response) => {
      self.shiftWarningProducts = products;
      self.shiftMessage = _.get(response, 'fields.content');
      this.$dialog.open({
        icon: _.get(this.cms, 'theme.successIcon'),
        text: this.getWarningMessage,
        buttons: [
          {
            type: 'main',
            text: this.$t('button.ok'),
            onClick: end ? this.finish : this.acknowledgeShiftWarning
          }
        ],
        persistent: true
      });
    });
  }

  private timeIsUpHandler() {
    this.resetLoadingStage();
    this.deadline = 0;
    CMSContentService.getStaticText('SUBMISSION-TIMEOUT', this.app.language)
            .then((response) => {
              this.shiftMessage = _.get(response, 'fields.content');
              this.$dialog.open({
                icon: _.get(this.cms, 'theme.errorIcon'),
                text: this.shiftMessage,
                buttons: this.getCntOrProCatButtons
              });
            });
  }

  private shiftUnavailable() {
    this.resetLoadingStage();
    this.deadline = 0;
    CMSContentService.getStaticText('SHIFT-UNAVAILABLE', this.app.language)
      .then((response) => {
        this.shiftMessage = _.get(response, 'fields.content') || this.$t('technical.message');
        this.$dialog.open({
          icon: _.get(this.cms, 'theme.errorIcon'),
          text: this.getErrorMessage,
          buttons: [
            {
              text: this.$t('button.closeDialog'),
              onClick: () => {
                EventBus.$emit('close-loading-dialog');
              }
            },
            {
              type: 'main',
              text: this.$t('button.proceedCatalogue'),
              onClick: this.acknowledgeShiftError
            }
          ]
        });
      });
  }

  private acknowledgeShiftError() {
    this.$router.push({name: 'landing', params: {skipConfirm: 'true'}});
  }

  private acknowledgeShiftWarning() {
    this.$dialog.close();
    const self = this;
    if (this.shiftWarnings.length > 0) {
      const shiftRejects = _.filter(this.shiftWarnings, (warning) => warning.message === SHIFT_REJECTED);
      if (shiftRejects.length > 0) {
        const products = _.map(shiftRejects, (warning) => warning.product);
        setTimeout(() => self.shiftWarning(products, SHIFT_REJECTED), 400);
        this.shiftWarnings = _.filter(this.shiftWarnings, (warning) => warning.message !== SHIFT_REJECTED);
      } else {
        const warning = this.shiftWarnings.shift();
        setTimeout(() => self.shiftWarning([warning.product], warning.message), 400);
      }
    } else if (!this.proposal.reviewed) {
      if (this.quotation.shiftResults.length > 0 && this.hasPayments.length > 0) {
        setTimeout(() => self.shiftWarning(this.hasPayments, 'CONTINUE-PAYMENT', true), 400, true);
      } else {
        this.finish();
      }
    }
  }

  private isPriceValid(id: string) {
    return this.afterUW || this.isPriceValidByProductId(id, this.app.isSavedProposal);
  }

  // this is only for product with and assume there is only one product in a proposal.
  get isQuoted() {
    return this.proposal.quoted;
  }

  private isSomeProductPricesValid() {
    if (this.afterUW) {
      return true;
    }

    for (const key in this.quotation.products) {
      if (this.quotation.products.hasOwnProperty(key)) {
          const id: string = this.quotation.products[key].id;
          if (this.isPriceValidByProductId(id, this.app.isSavedProposal)) {
            return true;
          }
      }
    }

    this.$global.enterAnimation();

    return false;
  }

  private isSomeProductPricesInvalid() {
    if (this.afterUW) {
      return false;
    }

    for (const key in this.quotation.products) {
      if (this.quotation.products.hasOwnProperty(key)) {
          const id: string = this.quotation.products[key].id;
          if (!this.isPriceValidByProductId(id, this.app.isSavedProposal)) {
            return true;
          }
      }
    }

    this.$global.enterAnimation();

    return false;
  }

  private getProductLevelFactValueById(id: string) {
    return this.getFactValueByFactID(id);
  }

  private getPriceToPayAfterRefunds() {
    return this.priceToPay() + (this.refunds - this.refundsTax);
  }

  private isExternalPricing() {
    // only support one product.
    if (this.isAllowExternalPricing) {
      return true;
    }
    return false;
  }

  // this is only for product with external pricing.
  private hasCommission() {
    // assume there is only one product chosen.
    return this.isAllowExternalPricing;
  }

  // this is only for product with external pricing.
  private getCommission() {
    // assume there is only one product chosen.
    // assume the first bucket is always the base premium.
    const bucket = this.getQuotedPrices()[0].price.buckets[0];
    return (bucket.totalPrice - bucket.refund) * 0.2;
  }

  private getTaxAfterRefunds() {
    return this.totalTax() + this.refundsTax;
  }

  private getPriceAfterRefunds() {
    return this.priceToPay() + this.totalTax() + this.refunds;
  }

  private getConfigByType(componentType: string, id?: string) {
    return id && _.keys(_.get(this.app.config.shiftMappings, 'AGREEMENT_FACTS', {})).includes(id) ? 'FactAgree' : this.getConfigFactTypeByFactType(componentType);
  }

  private getValidators(fact: any) {
    return FactService.getValidators(fact);
  }

  private updateFn(factData: any, factValue: any) {
    this.updateFact({
      productID: factData._productID,
      fact: factData,
      value: factValue
    });
  }

  private updateDeclarationFn(factData: any, factValue: any) {
    this.declarationMissing = this.declarationMissing && !factValue;
    this.updateFact({
      productID: factData._productID,
      fact: factData,
      value: factValue
    });
  }

  private getAssets(assets) {
    let ret: any[] = [];
    _.forEach(assets, (asset) => {
      if (!_.isEmpty(asset)) {
        let retAsset: any[] = [];
        if (!_.isEmpty(asset.benefits)) {
          const fact = _.find(asset.allFacts, (a) => _.includes(a.id, '.name'));
          retAsset.push({
            value: fact.currentValue,
            name: '',
            id: 'asset.name'
          });
        }
        retAsset = [...retAsset, ...asset.benefits];
        ret = [...ret, ...retAsset];
      }
    });
    return ret;
  }

  private async sendToCRM() {
    const businessSectionFacts = this.uniqueFacts[BUSINESS_SECTION_NAME];
    _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_SUBMISSION, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)(!this.declarationMissing, businessSectionFacts)
    .then(() => {
      this.setPardotAvailability(true);
    })
    .catch(() => {
      this.setPardotAvailability(false);
    });
    if (this.app.isRenewal && this.app.originUrl) {
      _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_RENEWAL_USED, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)()
        .then(() => { this.setPardotAvailability(true); })
        .catch(() => { this.setPardotAvailability(false); });
    }
  }

  private hasBenefits(component) {
    return _.get(component, 'benefits.length') ||
            (component.code === 'secondary' && _.get(component, 'assets[0].benefits.length'));
  }

  private hasTaxInfo() {
    return !!this.$t('product.summary.tax.comment');
  }

  @Watch('app.isPeInProgress')
  private onPeCompleted(value: boolean) {
    if (value === false) {
      this.$nextTick(() => {
        this.processDeclarationFactsFunc();
      });
    }
  }

}
