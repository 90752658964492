




























































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import {Action, Getter, State} from 'vuex-class';
import BasicInfo from './BasicInfo.vue';
import Configure from './Configure/Index.vue';
import CoverageDetails from './CoverageDetails.vue';
import Verification from './Verification.vue';
import Summary from './Summary.vue';
import Payment from './Payment.vue';
import EventBus from '@/common/EventBus';
import ConfigSummary from './Configure/ConfigSummary.vue';
import _ from 'lodash';
import Util from '@/utils/Util';
import {CMSContentService} from '@/services/CMSService';
import {FactService} from '@/services/FactService';
import {IQuotation} from '@/interfaces';
import WorkflowService from '@/services/WorkflowService';

@Component({
    name: 'PortalStepperItems',
    components: {
      BasicInfo,
      Configure,
      CoverageDetails,
      Verification,
      Summary,
      Payment,
      ConfigSummary
    }
  })
  export default class PortalStepperItems extends Vue {
    @State private app: any;
    @State private cms: any;
    @State private proposal: any;
    @State private products: any;
    @State private quotation!: IQuotation;
    @Prop() private value!: string;
    @Prop() private steps!: any;
    @Prop() private step!: any;
    @Prop() private afterUW!: boolean;
    @Action('app/setValue') private setValue: any;
    @Action('app/getQuotes') private getQuotes: any;
    @Action('app/setProposalNumber') private setAppProposalNumber: any;
    @Action('quotation/reset') private resetQuotation: any;
    @Action('quotation/setManualPricing') private setManualPricing: any;
    @Action('proposal/submitQuotes') private submitQuotes: any;
    @Action('proposal/incrementSaveProposalAttempts') private incrementSaveProposalAttempts: any;
    @Action('proposal/setChangeAfterReview') private setChangeAfterReview: any;
    @Action('proposal/setProposalNumber') private setProposalProposalId: any;
    @Action('proposal/setReviewed') private setReviewed: any;
    @Action('proposal/setConverted') private setConverted: any;
    @Action('proposal/setQuoted') private setQuoted: any;
    @Getter('quotation/getTotalQuotedPrice') private getTotalQuotedPrice!: () => number ;
    @Getter('quotation/getFactValueByFactID') private getFactValueByFactID!: (factID: string) => string;
    @Getter('quotation/isPriceValidByProductId') private isPriceValidByProductId!: (productID: string, isContinuation: boolean) => boolean;
    @Getter('quotation/getProducts') private getProducts!: () => any;
    @Action('quotation/updateFactUpload') private updateFactUpload!: (proposalId?) => void;

    private addressCopies = {};

    get isEmptyQuotationProduct() {
      const quotedProductCount = Object.keys(this.quotation.products).length || 0;
      return quotedProductCount === 0 ? true : false;
    }

    get totalPrice() {
      return this.getTotalQuotedPrice() || '-';
    }

    // this is only for landlords (priv-lan and dom-lan) and assume there is only one product in a proposal.
    get isExternalPricing() {
      const  code = this.proposal
        && this.proposal.products
        && this.proposal.products.length > 0
        && this.proposal.products[0].code;

      const product = _.find(this.products, (productEl) => productEl.code === code);

      if (product.allowExternalPricing) {
        return true;
      }
      return false;
    }

    get isQuoted() {
      return this.proposal.quoted;
    }

    get getQuoteButtonVisibility() {
      if (this.app.isContinuation) {
        return false;
      }
      // where it is on the summary page for a External Pricing product quote, and not having quotation, show the get-quote button.
      return this.step.ref === 'summary' && this.isExternalPricing && !this.isQuoted;
    }

    get payButtonVisibility() {
      if (this.step.ref === 'summary' && this.isExternalPricing && !this.isQuoted && !this.app.isContinuation) {
        return false;
      }
      if (this.step.ref === 'payment') {
        return this.app.isPayButtonVisible;
      }
      return true;
    }

    private getImage(name) {
      return _.get(this.cms, 'theme.' + name, '');
    }

    private updateAddressCopies(addressCopies) {
      this.addressCopies = addressCopies;
    }

    private openPricebox() {
      EventBus.$emit('openPricebox');
    }

    private created() {
      // launch this page directly is treated as a change to the existing proposal.
      this.setChangeAfterReview(true);
      // launch this page directly is treated as a change to the existing proposal, so invalidate the quotation.
      this.setQuoted(false);
      window.onbeforeunload = () => {
        const component = this.step;
        if (component) {
          Util.gaLogStepperEvent(this, 'exit', component.ref);
        }
        return;
      };
    }

    private beforeDestroy() {
      window.onbeforeunload = () => {
        return;
      };
    }

    private scrollToError(errors: any) {
      this.$nextTick( () => {
        const el = document.querySelector('div.v-input.error--text,span.error--text') as HTMLElement;
        const ct = Util.findAncestor(el, '.v-expansion-panel-content');
        if (_.get(ct, 'style.display') === 'none') {
          ct.style.display = 'block';
        }
        if (el) {
          const top = (window.pageYOffset + el.getBoundingClientRect().top);
          window.scrollTo({behavior: 'smooth', left: 0, top: top - (this.$vuetify.breakpoint.height / 2)});
        }
      });
    }

    private getLabelForNext() {
      if (this.afterUW) {
        return this.$t(this.step.ref === 'summary' ? 'button.toPayment' : 'button.pay');
      }
      if (this.step.ref === 'summary') {
          const productsWithValidPrice = this.proposal.products
              .filter((product) => this.isPriceValidByProductId(product.id, false));
          if ( _.get(this.app, 'quoteOnly', false)) {
            return this.$t('button.submitQuoteRequest');
          }
          if (productsWithValidPrice.length) {
            return this.$t('button.buyCover');
          } else {
            return this.$t('button.submitForReview');
          }
      }
      if (this.step.ref === 'payment') {
        return this.$t('button.pay');
      }
      return this.$t('button.nex');
    }

    get isBackVisible() {
      return this.step.ref !== 'payment' && !this.afterUW && (this.step.prev || !this.app.catalogueUrl);
    }

    get showTotal() {
      return this.step.ref === 'configure';
    }

    private async back() {
      // if existing proposal has been reviewed, set it back to manual pricing.
      if (this.proposal.reviewed) {
        this.setManualPricing({value: true, allowExternalPricing: this.isExternalPricing});
      }
      if (this.isExternalPricing && this.isQuoted) {
        this.setManualPricing({value: true, allowExternalPricing: this.isExternalPricing});
      }
      // any going back is treated as a change to the existing proposal.
      this.setChangeAfterReview(true);
      // any going back will invalidate the existing quotation.
      this.setQuoted(false);
      const prevStep = this.step.prev;
      const component = this.step;
      if (component) {
        Util.gaLogStepperEvent(this, 'previous', component.ref);
      }
      if (prevStep) {
        this.$global.leaveAnimation();
        this.$emit('input', prevStep);
      } else {
        this.resetQuotation();
        this.$router.push({name: 'landing'});
      }
    }

    private async next() {
      const value = this.step.ref;
      const component = this.getComponent(value);
      const step = this.getStep(component.ref);
      if (!step) {
        return;
      }
      const nextComponent = this.getComponent(this.step.next);
      const nextStep = nextComponent && this.getStep(nextComponent.ref);
      Util.gaLogStepperEvent(this, 'next', component.ref);
      setTimeout(() => {
        if (nextStep && _.isFunction(nextStep.beforeEnter)) {
          nextStep.beforeEnter();
        }
        if (_.isFunction(step.validate)) {
          step.validate();
        } else if (_.isFunction(step.submit)) {
          step.submit();
        } else {
          this.nextCallback();
        }
      }, 500);
    }

    private async getDomQuote() {
      const value = this.step.ref;
      const component = this.getComponent(value);
      const step = this.getStep(component.ref);
      if (_.isFunction(step.getQuote)) {
        step.getQuote();
      }
    }

    private nextCallback() {
      const nextStep = this.step.next;
      if (nextStep) {
        this.$emit('input', nextStep);
      }
    }

    get footerVisibility() {
      return this.app.isFooterVisible;
    }

    private getComponent(ref: string) {
      return this.steps[ref];
    }

    private getStep(reference: any) {
      return _.isArray(this.$refs[reference]) ? this.$refs[reference][0] : this.$refs[reference];
    }

    get showSaveProposalButton() {

      const hasValidPrice = this.isPriceValidByProductId(this.proposal.products[0].id, false);
      const showSaveProposalButtonOnValidPrice = _.get(this.app, 'showSaveProposalButtonOnValidPrice', false);

      if (showSaveProposalButtonOnValidPrice && !hasValidPrice) {
        return false;
      }

      if (this.proposal.reviewed && !this.proposal.changeAfterReview) {
        return false;
      }
      return _.get(this.app, 'showSaveProposalButton', false) && this.app.activeStep === 5;
    }

    private async saveProposal() {
      // need to set reviewed to false.
      // as per the logic in the Summary page, for a reviewed proposal, should create a new proposal.
      if (this.proposal.reviewed) {
        this.setReviewed(false);
        this.setConverted(false);
        this.setAppProposalNumber('');
        this.setProposalProposalId('');
      }

      this.incrementSaveProposalAttempts();
      const contactEmail = this.proposal.contactInfo.email;

      if (this.app.isRenewal && this.app.proposalId) {
        await this.getQuotes(this.app.proposalId);
      }

      if (contactEmail && this.proposal.saveProposalAttempts <= 3) {
        await this.submitQuotes();
        this.updateFactUpload(this.proposal.proposalId);
        if (this.proposal.proposalId) {
          // this is necessary when just saved a proposal from a new session.
          this.setValue({code: 'proposalId', value: this.proposal.proposalId});

          // if the email is used by an user in different type, the workflow will fail.
          const products = this.getProducts();
          const quotation = {};
          for (const key in products) {
            if (Object.prototype.hasOwnProperty.call(products, key)) {
              const product = products[key];
              const prices = {};
              for (const bucket of product.price.buckets) {
                prices[bucket.id] = bucket.totalPrice - bucket.refund;
              }
              quotation[product.code] = prices;
            }
          }
          await WorkflowService.runWorkflowSync(
            'send-save-proposal-email',
            {
              proposalId: this.proposal.proposalId,
              isRenewal: this.app.isRenewal,
              email: contactEmail,
              givenName: this.proposal.contactInfo.givenName || '',
              quotation
            },
            []
          );
          this.$dialog.open({ type: 'saved-proposal', info: 'Saved proposal' });
        }
      }
    }
  }
