















































































import EventBus from '@/common/EventBus';
import {FactService} from '@/services/FactService';
import Util from '@/utils/Util';
import GAUtil from '@/utils/GAUtil';
import {Component, Vue, Prop} from 'vue-property-decorator';

import {Action, Getter, State} from 'vuex-class';
import Proposal from '@/models/proposal';
import _ from 'lodash';
import FactDate from '@/components/common/form/fact/FactDate.vue';
import FactList from '@/components/common/form/fact/FactList.vue';
import FactSelection from '@/components/common/form/fact/FactSelection.vue';
import FactText from '@/components/common/form/fact/FactText.vue';
import FactMultilineText from '@/components/common/form/fact/FactMultilineText.vue';
import FactNumber from '@/components/common/form/fact/FactNumber.vue';
import FactOption from '@/components/common/form/fact/FactOption.vue';
import FactAgree from '@/components/common/form/fact/FactAgree.vue';
import FactLabel from '@/components/common/form/fact/FactLabel.vue';
import {BUSINESS_SECTION_NAME, default as ProductEngineUtils, USE_POLICY} from '@/utils/ProductEngineUtils';
import { IFactUpdatePayload, IQuotation, IQuotationProduct } from '@/interfaces';
import Illustration from '@/components/common/Illustration.vue';
import GeneralInformation from '@/views/auth/Portal/PortalStepperItems/GeneralInformation.vue';
import utils from '@/views/auth/Portal/PortalStepperItems/Utils';

const FIRST_NON_TECHNICAL_ERROR = 100;

const STEP = 'verification';

@Component({
  name: 'Verification',
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    FactDate,
    FactList,
    FactSelection,
    FactText,
    FactMultilineText,
    FactNumber,
    FactOption,
    FactAgree,
    FactLabel,
    Illustration,
    GeneralInformation
  },
})
export default class Verification extends Vue {
  @Prop() private next: any;
  @Prop() private img: any;
  @State private cms: any;
  @State private app: any;
  @Action('app/setPardotAvailability') private setPardotAvailability: any;
  @Action('quotation/updateFact') private updateFact!: (payload: IFactUpdatePayload) => void;
  @Getter('proposal/getSelectedProducts') private getSelectedProducts: any;
  @Getter('cms/getMessageByCode') private getMessageByCode: any;
  @Getter('quotation/getSortedQuotationProduct') private getSortedQuotationProduct!: () => IQuotationProduct[];
  @Getter('quotation/getUnderwritingFactsByProductID') private getUnderwritingFactsByProductID!: (productID: string) => any;
  @Getter('quotation/hasUnderwritingFactsByProductID') private hasUnderwritingFactsByProductID!: (productID: string) => boolean;
  @Getter('quotation/getFactValidationData') private getFactValidationData!: (productID: string, validatedFacts: string[]) => any[];
  @Getter('quotation/getCoverageDetailFacts') private getCoverageDetailFacts!: () => any;

  private noVerificationRequired = true;

  get uniqueFacts() {
    return this.getCoverageDetailFacts();
  }

  get productIcons() {
    const productIcons = {};
    _.forEach(this.cms.products, (product) => {
      productIcons[product.code] = product.fields.iconInverted;
    });
    return productIcons;
  }

  get products() {
    return this.getSortedQuotationProduct();
  }

  public validate(this: any) {
    this.$resetErrors();
    this.$validator.validate()
    .then(async (result: any) => {
      let hasShiftErrors = false;
      for (const product of this.products) {
        const usedFactIds = this.getUsedFactIds(product);
        const shiftResult = this.getFactValidationData(product.id, usedFactIds);

        for (const err of shiftResult) {
          if (err.error.code === 1 && Util.getDeclarationFacts().includes(err.error.relatedFacts[0])) {
            continue; // Declaration is on Summary page
          }
          hasShiftErrors = true;
          const factCode = Util.generateUid(err.productId, err.error.relatedFacts[0]);
          const message = err.error.code < FIRST_NON_TECHNICAL_ERROR ? this.$t('validations.shift.' + err.error.message) : err.error.message;
          this.$addError(factCode, message);
        }
        const missing = _.get(product, 'underwriting.factsMissing', []);
        for (const m of missing) {
          const factCode = Util.generateUid(product.code, m);
          const message = this.$t('validations.shift.REQUIRED_FIELD');
          this.$addError(factCode, message);
        }
      }
      if ((result === true || result.valid === true) && !hasShiftErrors) {
        this.$global.leaveAnimation();
        this.sendToCRM();
        this.next();
      } else {
        Util.gtmLogCustomEvent(this, 'click', 'tracked_error', {
            event_params : {
              error_type: 'form-error',
              error_message:  'Please provide valid input'
            }
        });
        this.$emit('error', this.xxErrors);
      }
    });
  }

  private getUsedFactIds(product: any) {
    const facts = this.getUnderwritingFactsByProductID(product.id);
    return _.map(facts, 'id');
  }

  private created() {
    EventBus.$on('TreeUpdated', _.debounce((id) => {
      EventBus.$emit('TreeUpdated-Verificaiton', id);
    }, Util.RE_RENDER_DEBOUNCE, Util.RE_RENDER_DEBOUNCE_OPTIONS));
  }

  private activated() {
    this.$global.enterAnimation();
    this.$nextTick(() => {
      EventBus.$emit('stepper-idle');
    });
    // EventBus.$on('TreeUpdated-Verification', (id) => {
    // });
    this.noVerificationRequired = true;
    GAUtil.ODLogVerification();
  }

  private deactivated() {
    EventBus.$emit('stepper-busy');
    EventBus.$off('TreeUpdated-Verification');
  }

  private getFactLevel(fact) {
    if (fact._level === undefined) {
      const id = _.get(fact, 'id', '');
      fact._level = (id.match(/\./g) || '').length;
    }
    return fact._level;
  }

  private getProductTitle(product: any) {
    const pp = _.find(this.getSelectedProducts(), (fp: any) => fp.code === product.code);
    return pp && pp.productTitle;
  }

  private hasUnderwritingQuestions(productId: string) {
    const hasQuestions = this.hasUnderwritingFactsByProductID(productId);
    const genericFactIds = utils.getGenericFactsConfig('verification.facts') || [];

    if (hasQuestions || genericFactIds.length > 0) {
      this.noVerificationRequired = false;
    }
    return hasQuestions;
  }

  private getConfigByType(componentType: string, fact: any) {
    return FactService.getConfigByType(fact);
  }

  private getValidators(fact: any) {
    return FactService.getValidators(fact);
  }

  private getFactUpdateFn(productId: string) {
    return (factData: any, factValue: any) => {
      this.updateFact({
        productID: productId,
        fact: factData,
        value: factValue,
        noSynonym: true
      });
    };
  }

  private async sendToCRM() {
    const businessSectionFacts = this.uniqueFacts[BUSINESS_SECTION_NAME];
    _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_VERIFICATION, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)(businessSectionFacts)
    .then(() => {
      this.setPardotAvailability(true);
    })
    .catch(() => {
      this.setPardotAvailability(false);
    });
  }

  private getFactUID = (productId: string, factId: string) => Util.generateUid(productId, factId);
}
